<template>
  <label>
    <input
      @change="$parent.$emit('input', value)"
      @blur="$parent.$emit('blur')"
      type="radio"
      :value="value"
      :name="name"
      v-bind="$attrs"
    />
    <span class="ml-2 label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'RadioButton',
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
