<template>
  <div>
    <RadioButton
      :class="index > 0 ? 'ml-8' : null"
      v-for="(option, index) in options"
      :label="option.label"
      :value="option.value"
      :key="index"
      :name="option.name"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import RadioButton from './RadioButton.vue';

export default {
  inheritAttrs: false,
  name: 'RadioButtonGroup',
  components: { RadioButton },
  props: {
    options: {
      required: true,
      type: Array,
    },
  },
};
</script>
